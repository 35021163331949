import Link from 'next/link'
import classNames from 'classnames/bind'

import { useMutation } from 'hooks/useMutation'

import ButtonLink from 'components/ButtonLink'
import UserContentImage from 'components/UserContentImage'

import { PromotionsPreviewMode } from 'modules/seller-hub/promotions/components/PromotionConfigureForm/PromotionConfigureForm'

import TRACK_PROMOTIONS_EVENT_MUTATION from './graphql/TrackPromotionsEventMutation.graphql'
import {
  TrackPromotionsEventMutation,
  TrackPromotionsEventMutationVariables
} from './graphql/__generated__/TrackPromotionsEventMutation'
import { GetCurrentPromotions_currentPromotions_slots_nodes } from '../../pages/CatalogListPage/graphql/__generated__/GetCurrentPromotions'
import { GetConfigurePromotionSlotDetails_currentSeller_promotionSlots_nodes } from 'modules/seller-hub/promotions/pages/PromotionConfigurePage/graphql/__generated__/GetConfigurePromotionSlotDetails'

import styles from './PromotionBanner.module.css'
const cx = classNames.bind(styles)

type PromotionBannerProps = {
  promotionSlot:
    | GetConfigurePromotionSlotDetails_currentSeller_promotionSlots_nodes
    | GetCurrentPromotions_currentPromotions_slots_nodes
  promotionsPreviewMode?: PromotionsPreviewMode
  className?: string
  isHomepageBanner?: boolean
  isButtonLocked?: boolean
}

const PromotionBanner = ({
  promotionSlot,
  promotionsPreviewMode,
  className,
  isHomepageBanner = false,
  isButtonLocked = false
}: PromotionBannerProps) => {
  const content = promotionSlot.content
  const catalog = promotionSlot.content?.catalog
  const sellerSlug = catalog?.seller?.slug
  const catalogSlug = catalog?.slug

  const [trackPromotionsEvent] = useMutation<TrackPromotionsEventMutation, TrackPromotionsEventMutationVariables>(
    TRACK_PROMOTIONS_EVENT_MUTATION,
    {
      toastOptions: {
        defaultErrorMessage: 'Failed to track promotion event'
      },
      onError: error => {
        console.error('Failed to track promotion event', error)
      }
    }
  )

  const handleClick = async () => {
    if (promotionsPreviewMode) return

    await trackPromotionsEvent({
      variables: {
        input: {
          promotionSlotId: promotionSlot.id,
          pageUrl: window.location.href
        }
      }
    })
  }

  const isMobilePreview = promotionsPreviewMode === 'mobile'

  return (
    <div className={cx(className, styles.container, { isHomepageBanner, isMobilePreview })} onClick={handleClick}>
      <Link href={`/${sellerSlug}/${catalogSlug}`}>
        <a>
          <div className={styles.cover}>
            <UserContentImage className={styles.coverImage} src={catalog?.photoMediumUrl} alt="" />
          </div>
        </a>
      </Link>
      <div className={styles.text}>
        <div className={styles.spotlight}>
          <span className={styles.spotlightDepartment}>
            {(promotionSlot as GetCurrentPromotions_currentPromotions_slots_nodes)?.promotion?.department?.name ?? ''}{' '}
            Spotlight
          </span>
          <span className={styles.spotlightSellerName}>{catalog?.seller?.displayName}</span>
        </div>

        <div className={styles.content}>
          {content && (
            <>
              <span className={styles.headline}>{content.headline}</span>
              <span className={styles.bodyText}>{content.bodyText}</span>
            </>
          )}

          <ButtonLink
            className={cx({ isButtonLocked })}
            href={`/${sellerSlug}/${catalogSlug}`}
            label={`Shop ${catalog?.seller?.displayName}`}
            icon="arrow-right"
            iconPosition="right"
            kind="dark"
          />
        </div>
      </div>
    </div>
  )
}

export default PromotionBanner
